<template>
  <div class="production">
    <h1>sandbox</h1>
    <br />
    <a href="http://kontexbi.com/ibot/ibot.php"
      >Click Here to run Sandbox Code
    </a>
    <br />
    <a href="http://kontexbi.com/ibot/ibot.php"
      >Push Sandbox to production
    </a>
  </div>
</template>
<script>
export default {
  computed: {
    localauth() {
      return this.$store.state.authenticated;
    },
  },
  mounted() {
    if (!this.localauth) {
      this.$router.push("/");
    }
  },
};
</script>
